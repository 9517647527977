import helix from './helix'
import Cookies from 'js-cookie';
import Banners from './banners';
import HelixForm from './HelixForm';
import allStartDates from '../../_data/start-dates';
import showCollateral from './collateral';

const banners = new Banners();

window.addEventListener('DOMContentLoaded', (e) => {
    // Menu button
    const menuButton = document.querySelector('button.menu');
    if (menuButton) {
        menuButton.addEventListener('click', (e) => {
            document.body.classList.toggle('nav-open')
        });
    }
    
    document.querySelectorAll('.helixform').forEach((form) => {
        let helixForm = new HelixForm();
        helixForm.validation = {
            "first_name": [
                {type: "required"}
            ],
            "last_name": [
                {type: "required"}
            ],
            "email": [
                {type: "required"},
                {type: "email"},
            ],
            "phone": [
                {type: "required"},
                {type: "phone"}
            ],
            "postal_code": [
                {type: "required"},
                {type: "postal_code"}
            ],
        }
        helixForm.initialize(form);
    })

	// Look for program code in the querystring
    // (ie. standalone rfi pages)
    if (window.URLSearchParams) {
        let searchParams = new URLSearchParams(window.location.search);
        let programCode = searchParams.get('program')
        if (programCode != null) {
            document.querySelectorAll('[name="program_code"]').forEach((input) => {
                input.value = programCode
            })
        }
    }
    

    /**
	 * YouTube players
	 */
	const ytApiKey = "AIzaSyAMtPQNVdy9rsvzRPjWvam_XYzherM4Poc";
	// go through each video module
	document.querySelectorAll('div.embed').forEach((video) => {
		// get thumbnail from YouTube if none is pre-defined
		// if (typeof video.dataset.frame == "undefined") {
		// 	// get the video id
		// 	let videoId = video.dataset.videoId;
		// 	// query YouTube API for thumbnail
		// 	let req = new XMLHttpRequest();
		// 	req.addEventListener('load', () => {
		// 		let data = JSON.parse(req.responseText);
		// 		let thumbnailUrl = data.items[0].snippet.thumbnails.standard.url;
		// 		video.style.backgroundImage = "url('" + thumbnailUrl + "')";
		// 	})
		// 	req.open('GET', 'https://www.googleapis.com/youtube/v3/videos?id=' + videoId + '&key=' + ytApiKey + '&part=snippet&fields=items(snippet/thumbnails/standard)');
		// 	req.send();
		// }
	});	
	document.querySelectorAll('div.embed a').forEach((link) => {
		link.addEventListener('click', (e) => {
			e.preventDefault();
			let parent = link.getParent('.embed');
			parent.innerHTML = parent.innerHTML.replace(/(<!--|-->)/g, '');
		})
	});


    // Get the current page's URL
    var currentPageUrl = window.location.href;

    // Find all form fields with the name "referrer_url" and update their values
    var formFields = document.querySelectorAll('input[name="referrer_url"]');
    formFields.forEach(function(field) {
    field.value = currentPageUrl;
    });

    // Find all form fields with the name "request_id" and update the values. This is for the CHEQ api.
    window.onCheqResponse = function(argument1, RequestId) {
        var formFields = document.querySelectorAll('input[name="request_id"]');
        formFields.forEach(function(field) {
            field.value = RequestId;
        })
    };

	// Show collateral download if necessary
	showCollateral();

	
    // Global start date replacement 
	if (typeof greaterThan == "undefined") var greaterThan = "01/01/2000"; 
	greaterThan = greaterThan.split('/');
	var greaterThanDate = new Date(greaterThan[2],(greaterThan[0] - 1),greaterThan[1]);
	//var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	var now = new Date();
	var threshold = 7; // number of days ahead it switches

    // set which start date group we're using on this page
    let startDates = allStartDates['all'];
    if (typeof window.startDateGroup != "undefined") {
        startDates = allStartDates[window.startDateGroup]
    }
    
	for(var i = 0; i < startDates.length; i++) {
		var date = startDates[i].split('/');
		var newDate = new Date(date[2],(date[0] - 1),date[1]);
		if (newDate.getTime() >= now.getTime() + (threshold*24*60*60*1000) && newDate >= greaterThanDate) {
			var dateReplacements = document.querySelectorAll('.date-replace')
			dateReplacements.forEach(function(dateReplacement){
				dateReplacement.innerHTML = "as soon as <strong>" + date[0] + '/' + date[1] + "</strong>";
			})
			break;
		}
	}
    
});


Node.prototype.getParent = function(selector) {
    var element = this;
    while (element = element.parentElement) { 
        if (element.matches(selector)) { 
            return element;
            break;
        } 
    }
}
